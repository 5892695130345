import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from './history';
import Home from './pages/Home';
import Video from './pages/Video/Video';
import Upload from './pages/Upload';
import NotFound from './pages/NotFound';

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={Video} path="/video" exact />
        <Route component={Upload} path="/upload" exact />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
