import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../utils/colors';
import { useDropzone } from 'react-dropzone';
import { Grid, Button, Typography } from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';

const useStyles = makeStyles(theme => ({
  areaUpload: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    maxWidth: '80rem',
    margin: '5rem auto',
    height: '20vh',
    border: `1px solid ${COLORS.liveBlue}`,
    backgroundColor: COLORS.white,
    borderRadius: '.5rem',
    cursor: 'pointer',
  },
  uploadBtn: {
    textAlign: 'center',
    fontWeight: 'bold',
    userSelect: 'none',
    fontSize: '.8rem',
    textTransform: 'unset',
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    width: '10rem',
    height: '36px',
    marginTop: '4rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',    
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,
      
    },
  },
  message: {
    fontWeight: 'bold',
    margin: '.3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.7rem',
    },
  },
}));

const UploadWidget = ({ files, setFiles, onUpload, setOnUpload }) => {
  const classes = useStyles();
  let fileInput = useRef(null);

  const [msg, setMsg] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    maxFiles: 5,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections[0].errors.forEach(err => {
          setMsg(err.message);
        });
        return;
      }

      setFiles(acceptedFiles);
      setOnUpload(true);
    },
  });

  const validatePdf = e => {
    let filesArr = Array.prototype.slice.call(e.target.files);
    let validate = true;
    let sizeok = true;
    if (filesArr) {
      for (let file of filesArr) {
        if (file.type !== 'application/pdf') {
          validate = false;
          break;
        }

        // 100 MiB
        if (file.size > 100 * 1024 * 1024) {
          sizeok = false;
          break;
        }
      }
    }
    if (validate === true) {
      if (filesArr.length > 5) {
        setMsg('Envie apenas 5 documentos de uma vez!');
      } else {
        if (sizeok === false) {
          setMsg(
            'O arquivo é muito grande para ser enviado. Tamanho Máximo: 100 MB',
          );
        } else {
          setOnUpload(true);
          setFiles([...filesArr]);
        }
      }
    } else {
      setMsg('Todos os arquivos devem estar no formato PDF');
    }
  };
  /* chamado no input */
  const onChange = e => {
    validatePdf(e);
  };

  return (
    <>
      <Grid className={classes.areaUpload} {...getRootProps()}>
        <Button startIcon={<UploadIcon />} className={classes.uploadBtn}>
          Upload
          <input
            id="file"
            name="files[]"
            type="file"
            multiple
            style={{ display: 'none' }}
            ref={fileInput}
            accept=".pdf"
            {...getInputProps()}
            onChange={onChange}
          />
        </Button>

        <Typography variant="subtitle2" className={classes.message}>
          Clique ou arraste os documentos aqui
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ color: 'red', fontWeight: 'bold', margin: '.3rem' }}
        >
          {msg}
        </Typography>
      </Grid>
    </>
  );
};

export default UploadWidget;
