import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles(theme => ({
  documentDiv: {
    [theme.breakpoints.down(565)]: {
      margin: '0 auto',
      padding: '0',
    },
  },
}));

const PdfViewer = props => {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderPdf, setRenderPdf] = useState(null);

  const renderPdfToBase64 = () => {
    let reader = new FileReader();
    reader.readAsDataURL(props.pathFile);
    reader.onloadend = e => {
      setRenderPdf(e.target.result);
    };
  };

  const fileLoadSucessHandler = ({ numPages }) => {
    setNumPages(numPages);
  };

  const fileLoadErrorHandler = () => {
    console.log('Deu erro');
    //console.error();
  };

  const goBackToFirstPage = () => {
    setPageNumber(1);
  };

  const goBackToLastPage = () => {
    setPageNumber(numPages);
  };

  const nextPageHandler = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPageHandler = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  renderPdfToBase64();

  return (
    <div>
      {renderPdf !== null ? (
        <div>
          <Document
            file={renderPdf}
            className={classes.documentDiv}
            onLoadSuccess={fileLoadSucessHandler}
            onLoadError={fileLoadErrorHandler}
            loading={'Carregando...'}
            error="Não foi possível carregar o arquivo"
          >
            <Page width={500} pageNumber={pageNumber} />
          </Document>
          <p style={{ marginTop: '1rem' }}>
            Página {pageNumber} de {numPages}
          </p>
        </div>
      ) : null}
      <div style={{ marginTop: '1rem' }}>
        <Button onClick={goBackToFirstPage}>
          <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
        </Button>
        <Button onClick={previousPageHandler}>
          <ArrowBackIosIcon />
        </Button>
        <Button onClick={nextPageHandler}>
          <ArrowForwardIosIcon />
        </Button>
        <Button onClick={goBackToLastPage}>
          <DoubleArrowIcon />
        </Button>
      </div>
    </div>
  );
};

export default PdfViewer;
