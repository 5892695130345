/* eslint-disable no-useless-escape */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';

import COLORS from '../utils/colors';

import dattasignLogo from '../assets/images/logo_dattasign.svg';
import dattasignBanner from '../assets/images/black-wave.png';

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.white,
    fontWeight: '800',
    fontSize: '1rem',
    textAlign: 'left',
    marginTop: '1rem',
  },

  advertiseContainer: {
    width: '10rem',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundImage: `url(${dattasignBanner})`,
    backgroundSize: 'cover',
    position: 'absolute',
    top: '250px',
    right: '50px',
    cursor: 'pointer',
    [theme.breakpoints.down(1080)]: {
      display: 'none'
    },
    [theme.breakpoints.between(1080, 1320)]: {
      right: '15px',
    },
    [theme.breakpoints.between(1320, 1520)]: {
      right: '25px',
    },
    [theme.breakpoints.between(1520, 1720)]: {
      right: '35px',
    },
    [theme.breakpoints.up(1720)]: {
      right: '50px',
    },
  },

  logo: {
    width: '8rem', 
    height: '2.5rem', 
    marginTop: '1rem'
  },

  contentContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'flex-start',
  },

  advertiseButton: {
    backgroundColor: '#FF670F',
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '700',
    verticalAlign: 'middle',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    margin: '1rem',
    width: '80%',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#E25D10',
      opacity: 0.9,
    },
  },
}));

export default function Advertise() {
  const classes = useStyles();

  return (
    <Grid
      className={classes.advertiseContainer}
      onClick={() => window.open('https://api.dattasign.com.br/api/token_courtesy_adequador')}
    >
      <div
        className={classes.contentContainer}
      >
        <img
          className={classes.logo}
          src={dattasignLogo}
          alt="Logo do Dattasign"
        />
      </div>
      <div
        className={classes.contentContainer}
      >
        <Typography
          className={classes.title}
        >
          Cadastre-se e assine 3 documentos grátis.
        </Typography>
      </div>
      <Button
        variant="contained"
        className={classes.advertiseButton}
        onClick={() => window.open('https://api.dattasign.com.br/api/token_courtesy_adequador')}
        type="button"
      >
        Experimente
      </Button>
    </Grid>
  );
}
