import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import dattasignLogo from '../assets/images/logo_dattasign.svg';
import blackBackground from '../assets/images/thin-black-wave.png';

const useStyles = makeStyles(theme => ({
  dattasignAd: {
    backgroundImage: `url(${blackBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1.5rem',
    cursor: 'pointer',
  },

}));

export default function ThinAdvertise() {
  const classes = useStyles();

  return (
    <Box className={classes.dattasignAd} onClick={() => window.open('https://api.dattasign.com.br/api/token_courtesy_adequador')}>
      <Typography
        style={{                     
          textAlign: 'left',
          fontWeight: '500',
          color: '#fff',
          fontSize: '14px',
          paddingLeft: '1rem'
        }}
      >
         Aproveite e <strong style={{ color: '#FF670F'}}>assine</strong> seu documento!
        <span style={{ fontSize: '12px', color: '#fff' , marginLeft: '.5rem', textDecoration: 'underline'}}>Clique aqui</span>  
      </Typography>
      
      <img
        style={{ width: '7rem', height: '3rem', paddingRight: '1rem' }}
        src={dattasignLogo}
        alt="Logo do Dattasign"
      />
    </Box>
  );
}
