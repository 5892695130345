import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from '../../components/navBar';
import { Link } from 'react-router-dom';
import COLORS from '../../utils/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from '../../components/footer';


const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '5rem',
  },
  innerContainer: {
    width: '60%',
    margin: '2rem auto',
    /* gap: '10px', */
    gap: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(1080)]: {
      width: '80%',
      margin: '2rem auto',
    },
    [theme.breakpoints.down(640)]: {
      width: '90%',
      margin: '2rem auto',
    },
  },

  inicioImagem: {   
    height: 500,
    width: 800,
    margin: '2rem auto',
    borderRadius: '1rem',    
    [theme.breakpoints.down(1360)]: {
      width: '80%',
      
    },
    [theme.breakpoints.down(500)]: {
      height: 400,
    },
  },

  linkReturn: {
    fontSize: '.9rem',
    color: COLORS.liveBlue,
    textTransform: 'none',
    textDecoration: 'underline',
    margin: '1rem auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const Video = () => {
  const classes = useStyles();
  return (
    <>
      <NavBar />
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          
        <iframe className={classes.inicioImagem} height="350"  src="https://www.youtube.com/embed/Y27vRCe52pk" 
        title="Como usar o Adequador" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen></iframe>      

          <Link to="/" className={classes.linkReturn}>
            <ArrowBackIcon
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            Retornar à página principal
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Video;
