import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

import DattasignPopupBanner from '../assets/images/dattasign-popup-banner.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AdvertiseDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <img
          src={DattasignPopupBanner}
          alt="Propaganda do Dattasign"
          style={{ width: '100%', cursor: 'pointer', position: 'relative' }}
          onClick={() => window.open('https://api.dattasign.com.br/api/token_courtesy_adequador')}
        />
        <CloseIcon
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            color: '#fff',
            cursor: 'pointer',
          }}
        />
      </BootstrapDialog>
    </div>
  );
}
