import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import FileSaver from 'file-saver';
/* import * as Yup from 'yup'; */
/* import { ErrorMessage, Field, Form, Formik } from "formik"; */
import {
  FormControl,
  Card,
  Grid,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { Modal, Fade, Backdrop } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CircularIndeterminate from '../components/progress';
import ModalFortify from '../components/modalFortify';
import ThinAdvertise from '../components/thinAdvertise';

import api from '../services/api';
import COLORS from '../utils/colors';
import fortifyHelpers from '../utils/fortifyHelpers';

import adequadorLogo from '../assets/images/adequador_logo.png';

const useStyles = makeStyles(theme => ({
  areaForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    margin: '1rem auto',
    borderRadius: '.5rem',
    minHeight: '65vh',
  },

  formContainer: {
    overflow: 'unset',
    width: '100%',
    paddingLeft: '0',
    [theme.breakpoints.between(1500, 1800)]: {
      paddingLeft: '3rem',
    },
    [theme.breakpoints.up(1800)]: {
      paddingLeft: '4rem',
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  docFormField: {
    height: '2.5rem',
    width: '24rem',
    maxWidth: '24rem',
    margin: '1rem 1.2rem',
  },

  selectField: {
    height: '3.5rem',
    width: '24rem',
    maxWidth: '24rem',
    margin: '1rem 1.2rem',
  },

  selectMinorField: {
    height: '3.5rem',
    /*  width: '14rem', */
    maxWidth: '11.5rem',
  },

  minorFields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
    maxWidth: '24rem',
    margin: '1rem 0',
    [theme.breakpoints.between(1500, 1650)]: {
      margin: '1rem .8rem 0px 0',
    },
  },

  botoes: {
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '700',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
    letterSpacing: '0.02857em',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    margin: '1rem',
    width: '12rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,
      boxShadow:
        '1px 4px 2px -1px rgb(0 0 0 / 10%), 1px 3px 3px 1px rgb(0 0 0 / 14%), 1px 2px 6px 1px rgb(0 0 0 / 12%)',
    },
  },

  successMessage: {
    color: COLORS.lightGreen,
    fontSize: '14px',
    fontWeight: '600',
  },

  errorMessage: {
    color: COLORS.redWarning,
    fontSize: '14px',
    fontWeight: '600',
  },

  error: {
    color: COLORS.redWarning,
    fontSize: '12px',
    fontWeight: '500',
    textAlign: 'left',
  },

  cliqueAqui: {
    color: COLORS.mediumBlue,
    cursor: 'pointer',
  },

  cssLabel: {
    color: COLORS.grey2,
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'red ',
  },

  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${COLORS.blue} !important`,
    },
  },
}));

const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '.5rem',
  boxShadow: 24,
  p: 4,
};

//for type of document
const typeOptions = [
  'Textos impressos, sem ilustração, em preto e branco',
  'Textos impressos, com ilustração, em preto e branco',
  'Textos impressos, com ilustração e cores',
  'Textos manuscritos, com ou sem ilustração, em preto e branco',
  'Textos manuscritos, com ou sem ilustração, em cores',
  'Fotografias e cartazes',
  'Plantas e mapas',
];
//place options
const placeOptions = [
  'Acre / AC',
  'Alagoas / AL',
  'Amapá / AP',
  'Amazonas / AM',
  'Bahia / BA',
  'Ceará / CE',
  'Distrito Federal / DF',
  'Espírito Santo / ES',
  'Goiás / GO',
  'Maranhão / MA',
  'Mato Grosso / MT',
  'Mato Grosso do Sul / MS',
  'Minas Gerais / MG',
  'Pará / PA',
  'Paraíba / PB',
  'Paraná / PR',
  'Pernambuco / PE',
  'Piauí / PI',
  'Rio de Janeiro / RJ',
  'Rio Grande do Norte / RN',
  'Rio Grande do Sul / RS',
  'Rondônia / RO',
  'Roraima / RR',
  'Santa Catarina / SC',
  'São Paulo / SP',
  'Sergipe / SE',
  'Tocantins / TO',
];

export const Formulario = props => {
  const classes = useStyles();

  const [form, setForm] = useState({
    documentTitle: '',
    author: '',
    subject: '',
    documentType: '',
    responsibleDigitization: '',
    regionDigitization: '',
    dateTimeDigitization: moment(new Date()).format('YYYY-MM-DD HH:mm'),
  });

  const [formatted, setFormatted] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormChanges = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDateTimeChange = newTimeValue => {
    setForm({
      ...form,
      dateTimeDigitization: moment(newTimeValue).format('YYYY-MM-DD HH:mm'),
    });
  };

  /* Modal */
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const [approvedChooseCertificate, setApprovedChooseCertificate] =
    useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* onSubmit */
  const customHandleSubmit = index => {
    setApprovedChooseCertificate(true);
    document.getElementById(`adequarBtn${index}`).click();
  };

  const uploadFile = async fortifyInfo => {
    try {
      handleOpen();
      progressLoading();

      // action start
      const formData = new FormData();

      formData.append('documentTitle', form.documentTitle);
      formData.append('author', form.author);
      formData.append('subject', form.subject);
      formData.append('responsibleDigitization', form.responsibleDigitization);
      formData.append('documentType', form.documentType);
      formData.append('regionDigitization', form.regionDigitization);
      formData.append('dateTimeDigitization', form.dateTimeDigitization);

      formData.append('file', props.pathFile);
      console.log('file no formData: ', props.pathFile);
      formData.append(
        'certificate',
        JSON.stringify({
          certPem: fortifyInfo.certificatePem,
        }),
      );

      const responseStart = await api.post(
        'api/document/upload/start',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      const { dataToSign, filename, module, documentTemp } = responseStart.data;

      // action complete
      const message = fortifyHelpers.fromHex(dataToSign);
      const alg = {
        name: fortifyInfo.privateKey.algorithm.name,
        hash: 'SHA-256',
      };

      let signature = fortifyHelpers.toHex(
        await fortifyInfo.provider.subtle.sign(
          alg,
          fortifyInfo.privateKey,
          message,
        ),
      );

      const data = {
        certificate: {
          signature: signature,
          certPem: fortifyInfo.certificatePem,
        },

        filename,
        module,
        documentTemp,
      };

      const responseComplete = await api.post(
        'api/document/upload/complete',
        data,
        {
          responseType: 'json',
        },
      );

      let res = await fetch(
        'data:application/pdf;base64,' + responseComplete.data,
      );
      let myBlob = await res.blob();

      handleOpen();
      setLoading(false);
      const fileBlob = new Blob([myBlob], {
        type: 'application/pdf',
      });
      FileSaver.saveAs(fileBlob, `${props.pathFile.name}`);

      let aux = [...props.data];
      aux[props.index] = { file: props.pathFile, ...form };
      props.setData(aux);

      setSuccess();
    } catch (error) {
      setLoading(false);
      setFailure();
    }
  };

  const setSuccess = () => {
    setFormatted(true);
    setMsg('Seu documento foi adequado com tecnologia DattaSign!');
  };

  const setFailure = () => {
    setFormatted(false);
    setMsg('Algo deu errado, por favor tente novamente.');
  };

  const progressLoading = () => {
    setLoading(true);
    setMsg('Por favor, aguarde enquanto adequamos seu documento');
  };

  const validateForm = () => {
    return (
      form.documentTitle &&
      form.author &&
      form.subject &&
      form.documentType &&
      form.responsibleDigitization &&
      form.regionDigitization &&
      form.dateTimeDigitization
    );
  };

  return (
    <Card className={classes.formContainer} elevation={0}>
      <Typography
        style={{
          textAlign: 'left',
          marginLeft: '1.2rem',
          marginTop: '1rem',
          fontSize: '18px',
        }}
      >
        Preencha os dados
      </Typography>

      <form
        id="formulario"
        className={classes.areaForm}
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit(() => customHandleSubmit(props.index))}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        {/* TITLE */}
        <FormControl style={{ width: '100%' }}>
          <TextField
            error={errors.documentTitle}
            className={classes.docFormField}
            style={{ marginBottom: '1rem' }}
            variant="outlined"
            type="text"
            name="documentTitle"
            {...register('documentTitle', {
              required: 'Título do documento é obrigatório',
              minLength: {
                value: 5,
                message: 'O título deve conter pelo menos 5 caracteres',
              },
            })}
            value={form.documentTitle}
            onChange={handleFormChanges}
            label="Título do Documento"
          />
          <FormHelperText style={{ marginLeft: '2.2rem' }}>
            {errors.documentTitle && (
              <p className={classes.error}>{errors.documentTitle.message}</p>
            )}
          </FormHelperText>
        </FormControl>

        {/* AUTHOR */}
        <FormControl style={{ width: '100%' }}>
          <TextField
            error={errors.author}
            className={classes.docFormField}
            style={{ marginBottom: '1rem' }}
            variant="outlined"
            type="text"
            name="author"
            {...register('author', {
              required: 'Autor do documento é obrigatório',
              minLength: {
                value: 5,
                message: 'O autor deve conter pelo menos 5 caracteres',
              },
            })}
            value={form.author}
            onChange={handleFormChanges}
            label="Autor do Documento"
            inputProps={{ name: 'author' }}
          />
          <FormHelperText style={{ marginLeft: '2.2rem' }}>
            {errors.author && (
              <p className={classes.error}>{errors.author.message}</p>
            )}
          </FormHelperText>
        </FormControl>
        {/* SUBJECT */}
        <FormControl style={{ width: '100%' }}>
          <TextField
            error={errors.subject}
            className={classes.docFormField}
            style={{ marginBottom: '1rem' }}
            name="subject"
            variant="outlined"
            type="text"
            {...register('subject', {
              required: 'Assunto do documento é obrigatório',
              minLength: {
                value: 5,
                message: 'O assunto deve conter pelo menos 5 caracteres',
              },
            })}
            value={form.subject}
            onChange={handleFormChanges}
            label="Assunto do Documento"
            inputProps={{ name: 'subject' }}
          />
          <FormHelperText style={{ marginLeft: '2.2rem' }}>
            {errors.subject && (
              <p className={classes.error}>{errors.subject.message}</p>
            )}
          </FormHelperText>
        </FormControl>
        {/* TYPE */}
        <FormControl style={{ width: '100%' }}>
          <InputLabel
            style={{ marginTop: '.8rem', marginLeft: '2rem' }}
            id="selectLabel"
          >
            Tipo do Documento
          </InputLabel>
          <Select
            labelid="selectLabel"
            label="Tipo do Documento"
            className={classes.selectField}
            name="documentType"
            variant="outlined"
            {...register('documentType', {
              required: 'Tipo do documento é obrigatório',
            })}
            type="text"
            value={form.documentType}
            onChange={handleFormChanges}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>Tipo do Documento</em>;
              }
              return selected;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value="">
              <em>Tipo do Documento</em>
            </MenuItem>
            {typeOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ marginLeft: '1rem' }}>
            {errors.documentType && (
              <p className={classes.error}>{errors.documentType.message}</p>
            )}
          </FormHelperText>
        </FormControl>
        {/* RESPONSIBLE */}
        <FormControl style={{ width: '100%' }}>
          <TextField
            error={errors.responsibleDigitization}
            className={classes.docFormField}
            style={{ margin: '0 1.2rem 1rem' }}
            name="responsibleDigitization"
            variant="outlined"
            type="text"
            {...register('responsibleDigitization', {
              required: 'Responsável pela digitalização é obrigatório',
              minLength: {
                value: 5,
                message: 'O responsável deve conter pelo menos 5 caracteres',
              },
            })}
            label="Responsável pela Digitalização"
            value={form.responsibleDigitization}
            onChange={handleFormChanges}
            inputProps={{ name: 'responsibleDigitization' }}
          />
          <FormHelperText
            style={{ marginLeft: '2.2rem', marginBottom: '.2rem' }}
          >
            {errors.responsibleDigitization && (
              <p className={classes.error}>
                {errors.responsibleDigitization.message}
              </p>
            )}
          </FormHelperText>
        </FormControl>

        <FormControl style={{ width: '100%' }}>
          <InputLabel
            style={{ margin: '.7rem 2rem 0', fontSize: '1rem' }}
            id="selectLabel"
          >
            Local da Digitalização
          </InputLabel>
          <Select
            labelid="selectLabel"
            label="Local da Digitalização"
            className={classes.docFormField}
            name="regionDigitization"
            variant="outlined"
            {...register('regionDigitization', {
              required: 'Local da digitalização é obrigatório',
            })}
            type="text"
            value={form.regionDigitization}
            onChange={handleFormChanges}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>Local da Digitalização</em>;
              }
              return selected;
            }}
            inputProps={{
              name: 'regionDigitization',
              'aria-label': 'Without label',
            }}
            style={{ height: '3.5rem' }}
          >
            <MenuItem disabled value="">
              <em>Estado / UF</em>
            </MenuItem>
            {placeOptions.map(placeOption => (
              <MenuItem key={placeOption} value={placeOption}>
                {placeOption}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ marginLeft: '1rem' }}>
            {errors.regionDigitization && (
              <p className={classes.error}>
                {errors.regionDigitization.message}
              </p>
            )}
          </FormHelperText>
        </FormControl>
        <FormControl
          className={classes.docFormField}
          style={{ width: '100%', margin: '.3rem .4rem 0 0 !important' }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={5}>
              <DateTimePicker
                renderInput={params => (
                  <TextField variant="outlined" {...params} />
                )}
                inputFormat="dd/MM/yyyy HH:mm"
                mask="__/__/____ __:__ _M"
                disableFuture
                hideTabs
                color="inherit"
                label="Data e Hora da Digitalização"
                labelProps={{ fontSize: '.9rem' }}
                value={form.dateTimeDigitization}
                onChange={handleDateTimeChange}
                minDate={new Date('2000-01-01')}
                maxDate={new Date()}
              />
            </Stack>
          </LocalizationProvider>
        </FormControl>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem auto',
          }}
        >
          <ModalFortify
            index={props.index}
            approvedChooseCertificate={approvedChooseCertificate}
            setApprovedChooseCertificate={setApprovedChooseCertificate}
            validateForm={validateForm}
            uploadFile={uploadFile}
          />
        </Grid>
      </form>

      {open ? (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={modalStyle}>
                {loading ? (
                  <CircularIndeterminate />
                ) : formatted ? (
                  <>
                    <ThinAdvertise />
                    <img
                      style={{ width: '10rem', height: '10rem' }}
                      src={adequadorLogo}
                      alt="Logo do adequador"
                    />
                  </>
                ) : (
                  <HighlightOffIcon
                    style={{
                      color: COLORS.redWarning,
                      width: '8rem',
                      height: '8rem',
                      marginbottom: '2rem',
                    }}
                  />
                )}
                {loading ? (
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    style={{
                      marginTop: '3rem',
                      textAlign: 'center',
                      fontWeight: '500',
                      fontSize: '14px',
                    }}
                  >
                    {msg}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                      style={{
                        marginTop: '1rem',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                      className={
                        formatted
                          ? classes.successMessage
                          : classes.errorMessage
                      }
                    >
                      {msg}
                    </Typography>
                    {/* {formatted ? 
                      (
                      <Typography
                      style={{
                        marginTop: '2rem',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: '600'
                      }}>
                        Se seu download não tiver começado automaticamente,&nbsp;  
                        <u className={classes.cliqueAqui} onClick={() => uploadFile()}>Clique aqui</u>
                      </Typography>
                    ) : null
                    } */}
                  </>
                )}
              </Box>
            </Fade>
          </Modal>
        </div>
      ) : null}
    </Card>
  );
};

export default Formulario;
