const fortifyHelpers = {
  toHex(buffer) {
    let buf = new Uint8Array(buffer),
      splitter = '',
      res = [],
      len = buf.length;

    for (let i = 0; i < len; i++) {
      let char = buf[i].toString(16);
      res.push(char.length === 1 ? '0' + char : char);
    }
    return res.join(splitter);
  },

  fromHex(hexString) {
    let res = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < hexString.length; i = i + 2) {
      let c = hexString.slice(i, i + 2);
      res[i / 2] = parseInt(c, 16);
    }
    return res.buffer;
  },
};

export default fortifyHelpers;
