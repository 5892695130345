import React, { useState, useEffect } from 'react';
import { Modal, Fade, Backdrop } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../utils/colors';

import { Box, Button } from '@material-ui/core';

const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '.5rem',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  botoes: {
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '700',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
    letterSpacing: '0.02857em',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    margin: '1rem',
    width: '12rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,
      boxShadow:
        '1px 4px 2px -1px rgb(0 0 0 / 10%), 1px 3px 3px 1px rgb(0 0 0 / 14%), 1px 2px 6px 1px rgb(0 0 0 / 12%)',
    },
  },
});

export const ModalFortify = props => {
  const {
    index,
    validateForm,
    approvedChooseCertificate,
    setApprovedChooseCertificate,
    uploadFile,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [isRendered, setIsRendered] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setApprovedChooseCertificate(false);
  };

  const handleFortify = modalFortify => {
    // https://fortifyapp.com/docs/webcomponents/fortify-certificates/readme#properties
    var el = document.createElement('peculiar-fortify-certificates');
    el.debug = true;
    el.filters = {};
    el.language = 'pt';

    el.addEventListener('selectionCancel', handleClose);

    el.addEventListener('selectionSuccess', async event => {
      try {
        let provider = await event.detail.socketProvider.getCrypto(
          event.detail.providerId,
        );

        let certificate = await provider.certStorage.getItem(
          event.detail.certificateId,
        );

        let certificatePem = await provider.certStorage.exportCert(
          'pem',
          certificate,
        );

        let privateKey = await provider.keyStorage.getItem(
          event.detail.privateKeyId,
        );

        handleClose();

        await uploadFile({
          provider,
          certificatePem,
          privateKey,
        });
      } catch (error) {
        console.info(error);
      }
    });

    modalFortify.appendChild(el);
    setIsRendered(true);
  };

  useEffect(() => {
    // Credits: https://stackoverflow.com/questions/16149431/make-function-wait-until-element-exists/35211286
    if (open) {
      // set up the mutation observer
      const observer = new MutationObserver((mutations, me) => {
        const boxModalFortify = document.getElementById(
          `boxModalFortify${index}`,
        );
        if (!isRendered && boxModalFortify) {
          handleFortify(boxModalFortify);
          me.disconnect(); // stop observing
          return;
        }
      });
      // start observing
      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isRendered, index, approvedChooseCertificate]);

  return (
    <>
      <Button
        disabled={!validateForm()}
        id={`adequarBtn${index}`}
        variant="contained"
        className={classes.botoes}
        style={{ marginTop: '1rem' }}
        onClick={approvedChooseCertificate ? handleOpen : () => {}}
        type={approvedChooseCertificate ? 'button' : 'submit'}
      >
        Adequar Documento
      </Button>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          keepMounted
        >
          <Fade in={open}>
            <Box sx={modalStyle} id={`boxModalFortify${index}`}></Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default ModalFortify;
