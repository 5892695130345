import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import COLORS from '../utils/colors';

const useStyles = makeStyles(theme => ({
  sectionFooter: {
    backgroundColor: COLORS.black,
    color: COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    height: 'fit-content',
    textAlign: 'left',
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '4rem auto',
    alignItems: 'center',
    textAlign: 'left',
  },

  direitosReservados: {
    fontSize: '1rem', 
    margin: '1rem auto', 
    textAlign: 'center',
    [theme.breakpoints.down(500)]: {
      fontSize: '.9rem'
    },
  }

}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.sectionFooter} position="static">
      <Box className={classes.info}>        
        <Typography
          variant="subtitle2"
          className={classes.direitosReservados}
        >
          © 2021 Adequador. Todos os direitos reservados.
        </Typography>
      </Box>
      
    </Box>
  );
}
