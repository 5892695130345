import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Box,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import COLORS from '../utils/colors';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import adequadorLogo from '../assets/images/adequador_logo.svg';
import adequadorPDF from '../assets/adequador_manual_do_usuario.pdf';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: COLORS.white,
    borderBottom: '1px solid #E4E5E9',
  },

  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    padding: '0 1rem 0 0',
    width: '70%',
    [theme.breakpoints.down(1080)]: {
      width: '83%',
    },
  },

  logoDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  navLinks: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    [theme.breakpoints.between(1080, 1260)]: {
      width: '65%',
    },
    [theme.breakpoints.between(1260, 1540)]: {
      width: '60%',
    },
  },

  navLinksDrawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  logo: {
    cursor: 'pointer',
    color: COLORS.gray1,
    textDecoration: 'none',
    marginLeft: '.2rem',
    [theme.breakpoints.down(680)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down(600)]: {
      margin: '1rem 0 1rem .5rem!important'
    },
    [theme.breakpoints.between(600, 1080)]: {
      marginLeft: '1.2rem'
    }
  },

  botoes: {
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    letterSpacing: '0.02857em',    
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    width: '9rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,      
    },
    [theme.breakpoints.between(1080, 1400)]: {
      width: '8rem',
    },
  },

  link: {
    textDecoration: 'none',
    color: COLORS.gray1,
    fontSize: '1rem',
    alignSelf: 'center',
    '&:hover': {
      color: COLORS.liveBlue,
      borderBottom: `1px ${COLORS.liveBlue} solid`,
    },
    '&:active': {
      color: COLORS.liveBlue,
      borderBottom: `1px ${COLORS.liveBlue} solid`,
      fontWeight: 700,
    },
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down(1080));
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <CssBaseline />
        <Toolbar className={classes.toolBar}>
          <Link to="/" className={classes.logo}>
            <img
              className={classes.logoImg}
              style={{ width: '12rem', height: '3.5rem' }}
              src={adequadorLogo}
              alt="Logo do adequador"
            />
          </Link>
          {isMobile ? (
            <div>
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                className={classes.menuIcon}
              >
                <MenuIcon />
              </Button>
              <Drawer
                anchor="right"
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
              >
                <List className={classes.navLinksDrawer}>
                  <ListItem button>
                    <ListItemText>
                      <Link to="/" className={classes.link}>
                        Home
                      </Link>
                    </ListItemText>
                  </ListItem>

                  <ListItem button>
                    <ListItemText>
                      <a
                        href={adequadorPDF}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}
                      >
                        Manual de Uso
                      </a>
                    </ListItemText>
                  </ListItem>

                  <Link to="/video" className={classes.link}>
                    Vídeo Explicativo
                  </Link>

                  <ListItem button>
                    <ListItemText>
                      <Link to="/upload" className={classes.link}>
                        Upload
                      </Link>
                    </ListItemText>
                  </ListItem>
                </List>
              </Drawer>
            </div>
          ) : (
            <div className={classes.navLinks}>
              <Link to="/" className={classes.link}>
                Home
              </Link>

              <a
                href={adequadorPDF}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Manual de Uso
              </a>
              
              <Link to="/video" className={classes.link}>
                Vídeo Explicativo
              </Link>
             
              <Button className={classes.botoes} onClick={() => history.push('/upload')}>
                  Upload
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
