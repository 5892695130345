import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import COLORS from './utils/colors';
import Routes from './routes';

const useGlobalStyles = makeStyles({
  '@global': {
    html: {
      fontFamily: 'Noto Sans',
    },
    body: {
      backgroundColor: COLORS.background,
    },
    main: {
      position: 'relative',
    }
  },
});

const theme = createTheme({});

function MyThemeProvider({ children }) {
  useGlobalStyles();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

function App() {
  return (
    <main className="App">
      <MyThemeProvider>
        <Routes />
      </MyThemeProvider>
    </main>
  );
}

export default App;
