/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from '../../components/navBar';
import UploadWidget from '../../components/uploadWidget';
import PdfViewer from '../../components/pdfViewer';
import Formulario from '../../components/formulario';
import Footer from '../../components/footer';
import COLORS from '../../utils/colors';
import { Grid, Divider, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import Advertise from '../../components/advertise';

import adequadorPDF from '../../assets/adequador_manual_do_usuario.pdf';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '500',
    color: COLORS.black,
    textAlign: 'center',
    padding: '4rem',
    [theme.breakpoints.down(780)]: {
      fontSize: '1.3rem!important',
      padding: '2rem 0!important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      padding: '2rem 1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.2rem',
      padding: '4rem 10rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.4rem',
      padding: '6rem 12rem',
      width: '80%',
    },
  },

  textDownloadManual: {
    fontSize: '1.125rem',
    color: COLORS.black,

    '& a': {
      color: COLORS.liveBlue,
      fontSize: '1.25rem',
    },
  },
  
  subText: {
    color: COLORS.black,
    fontWeight: '800',
    fontSize: '1.25rem',
    width: '80%',
    [theme.breakpoints.down(1080)]: {
      fontSize: '1rem',
      textAlign: 'center',
      marginTop: '1.5rem',
    },
  },
  
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: COLORS.background,
  },

  areaUploadBefore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '70%',
    margin: '1rem auto',
    height: '90vh',
  },

  areaUpload: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    margin: '1rem auto',
    height: '20vh',
    border: `1px solid ${COLORS.liveBlue}`,
    borderRadius: '.5rem',
  },

  areaUploadAfter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    margin: '1rem auto',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: COLORS.white,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 8%)',
    [theme.breakpoints.between(0, 805)]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.between(805, 1280)]: {
      width: '65%',
      height: '100%',
    },
    [theme.breakpoints.between(1280, 1650)]: {
      width: '70%',
      alignItems: 'center',
    },
  },

  linkReturn: {
    fontSize: '.9rem',
    color: COLORS.liveBlue,
    textTransform: 'none',
    textDecoration: 'underline',
    margin: '1rem auto 1.5rem auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  pdfTabs: {
    color: 'black',
    backgroundColor: COLORS.white,
    fontWeight: '800',
    borderRadius: '.5rem',
    cursor: 'pointer',
    [theme.breakpoints.down(1280)]: {
      padding: '0',
      margin: '0',
    },
  },

  divider: {
    [theme.breakpoints.down(565)]: {
      width: '100%',
      margin: '0 auto',
    },
  },

  documentGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.white,
    marginBottom: '3rem',
    [theme.breakpoints.down(1420)]: {
      alignItems: 'center',
      /*     flexDirection: 'column', */
      justifyContent: 'center',
    },
    [theme.breakpoints.down(1650)]: {
      justifyContent: 'space-evenly',
    },
  },

  documentViewer: {
    width: '46%',
    height: '72vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dcdcdc',
    borderRadius: '3px',
    marginRight: '1rem',
    marginBottom: '.5rem',
    overflow: 'scroll',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
    },
  },

  documentForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    /* height: '75vh', */
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      alignItems: 'center',
    },
    [theme.breakpoints.between(1500, 1650)]: {
      marginRight: '2rem',
    },
  },

  botoes: {
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '700',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
    letterSpacing: '0.02857em',    
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    margin: '1rem',
    width: '12rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,     
    },
  },

  customFileUpload: {
    display: 'inline-block',
    cursor: 'pointer',
    borderRadius: '20px',
  },

  errorMessage: {
    color: COLORS.redWarning,
    fontSize: '14px',
    fontWeight: '600',
  },

  container: {
    width: '80vw',
    height: '80vh',
    display: 'flex',
    margin: '1rem auto',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

export default function Upload(props) {
  const classes = useStyles();

  const [isMobile, setIsMobile] = useState(false);

  let largura = window.screen.width;

  useEffect(() => {
    //credit : https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
    .test(navigator.userAgent.substr(0,4))){
      setIsMobile(true);
    }

  }, [largura, isMobile])

  /* STATES */
  /* uploadWidget */
  //get documents
  const [files, setFiles] = useState([]);

  //are documents uploaded?
  const [onUpload, setOnUpload] = useState(false);

  const [data, setData] = useState([]);

  /* Document Context according to referred tab */
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <NavBar />
      
      {isMobile ? (
        <Grid className={classes.container}>
        <Typography className={classes.title}>
          Serviço indisponível em dispositivos móveis
        </Typography>   
        <MobileOffIcon style={{ color: COLORS.black, width: '4rem', height: '4rem'}}/>
        <Typography className={classes.subText} style={{ marginTop: '4rem'}}>
          Para fazer upload de documentos, acesse a plataforma através de um computador.
        </Typography>   
        <Link to="/" className={classes.linkReturn}
        style={{ marginTop: '5rem'}}>
          <ArrowBackIcon
            style={{ marginRight: '.5rem', cursor: 'pointer' }}
          />
          Retornar à página principal
        </Link>
      </Grid>
      ) :
      !onUpload  ? (
        <Grid className={classes.areaUploadBefore}>
          <Typography className={classes.title}>
            Faça upload de um documento para adequá-lo
          </Typography>

          <div className={classes.textDownloadManual}>
            Em caso de dúvidas, baixe o{' '}
            <a
              href={adequadorPDF}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Manual de Uso
            </a>
          </div>

          <UploadWidget
            selectedFile={props.selectedFile}
            setSelectedFile={props.setSelectedFile}
            files={files}
            setFiles={setFiles}
            onUpload={onUpload}
            setOnUpload={setOnUpload}
          />

          <Typography
            variant="subtitle2"
            style={{ fontSize: '.8rem', margin: '2.5rem auto' }}
          >
            <strong>Envie apenas pdfs.</strong> <br />
            <br /> <strong>Limite de 5 envios simultâneos.</strong> <br />
            <br />
            <strong>Lembre-se de salvar seu arquivo.</strong>
          </Typography>

          <Link to="/" className={classes.linkReturn}>
            <ArrowBackIcon
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            Retornar à página principal
          </Link>
          <Advertise />
        </Grid>
      ) : (
        <Grid container className={classes.body}>
          <Grid container className={classes.areaUploadAfter}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                style={{ marginLeft: '2.8rem' }}
              >
                <Box sx={{ maxWidth: 600 }}>
                  <Tabs
                    className={classes.pdfTabs}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    onChange={handleChange}
                    value={value}
                  >
                    {files.map((file, index) => {
                      return (
                        <Tab
                          label={'Pdf ' + [index + 1]}
                          style={{ cursor: 'pointer' }}
                          key={index}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </Stack>
              <Divider
                orientation="horizontal"
                style={{ marginTop: '.2rem' }}
                className={classes.divider}
              />
              {files.map((file, index) => {
                return (
                  <TabPanel value={value} key={index} index={index} >
                    <Grid container className={classes.documentGrid}>
                      <Grid container className={classes.documentViewer}>
                        <PdfViewer pathFile={file} />
                      </Grid>
                      <Grid container className={classes.documentForm}>
                        <Formulario
                          files={files}
                          setFiles={setFiles}
                          pathFile={file}
                          data={data}
                          setData={setData}
                          index={index}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                );
              })}
            </div>
          </Grid>

          <Link to="/" className={classes.linkReturn}>
            <ArrowBackIcon
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            Retornar à página principal
          </Link>
          <Advertise />
        </Grid>
      )}
      
      <Footer />
    </>
  );
}
