import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import NavBar from '../../components/navBar';
import Footer from '../../components/footer';
import COLORS from '../../utils/colors';
import praticidade from '../../assets/images/Praticidade.svg';
import seguranca from '../../assets/images/Segurança.svg';
import validade from '../../assets/images/Validade.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import AdvertiseDialog from '../../components/advertiseDialog';

const useStyles = makeStyles(theme => ({
  body: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  inicioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1080)]: {
      minHeight: '100vh',
    },
  },

  secaoInicio: {
    height: '80vh',
    width: '70%',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: '4rem auto',
    verticalAlign: 'baseline',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '0 auto!important',
    },
    [theme.breakpoints.down(1080)]: {
      margin: '2rem auto',
      height: 'fit-content',
      width: '100%',
    },
  },

  divInicio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down(1080)]: {
      alignItems: 'center',
    },
  },

  titleAndImage: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    maxHeight: '40vh',
    color: COLORS.black,
    textAlign: 'left',
    width: '90%',
    marginTop: '.5rem',
    [theme.breakpoints.down(780)]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down(1080)]: {
      margin: '1.5rem auto',
      width: '80%',
    },
    [theme.breakpoints.between(1060, 1500)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up(1700)]: {
      fontSize: '2rem',
    },
  },

  subText: {
    color: '#747474',
    fontSize: '1.2rem',
    textAlign: 'left',
    width: '80%',
    [theme.breakpoints.down(1080)]: {
      fontSize: '1rem',
      textAlign: 'left',
      margin: '1rem auto 2rem auto',
    },
    [theme.breakpoints.between(1080, 1400)]: {
      fontSize: '1rem',
      marginTop: '1rem',
    },
  },

  titleAndSubText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  inicioSubtitulos: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '1rem',
    width: '100%',
    [theme.breakpoints.down(339)]: {
      margin: '0 3rem!important',
    },
    [theme.breakpoints.between(339, 400)]: {
      width: '100%!important',
      margin: '0 1rem!important',
    },
    [theme.breakpoints.between(400, 500)]: {
      margin: '0 3.3rem!important',
    },
    [theme.breakpoints.between(500, 680)]: {
      margin: '0 1rem  0 4.5rem',
    },
    [theme.breakpoints.between(680, 800)]: {
      margin: '0 1rem  0 5.2rem',
    },
    [theme.breakpoints.between(800, 900)]: {
      margin: '0 1rem  0 6rem',
    },
    [theme.breakpoints.between(900, 1080)]: {
      margin: '0 1rem  0 6.5rem',
    },
    [theme.breakpoints.down(1080)]: {
      justifyContent: 'center',
      width: '80%',
    },
  },

  inicioSubtitulosTipografia: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    fontSize: '1.1rem',
    lineHeight: '1.25rem',
    fontWeight: '700',
    color: COLORS.liveBlue,
    [theme.breakpoints.between(1080, 1280)]: {
      fontSize: '.7rem',
      marginRight: '.7rem',
    },
    [theme.breakpoints.between(1280, 1500)]: {
      fontSize: '.9rem',
      marginRight: '1.25rem',
    },
    [theme.breakpoints.between(1500, 1700)]: {
      fontSize: '1rem',
      marginRight: '1.5rem',
    },
  },

  passosTipografia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '1.8rem',
    textAlign: 'left',
    lineHeight: '1.25rem',
    fontWeight: '700',
    margin: '2rem auto 1rem auto',
    padding: '1rem',
    color: COLORS.lightBlue,
    width: '70%',
    [theme.breakpoints.down(554)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down(1300)]: {
      marginTop: '4rem',
    },
    [theme.breakpoints.between(1060, 1500)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up(1700)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      top: '100px',
    },
  },

  passo2Div: {
    width: '100%',
    backgroundColor: COLORS.white,
    [theme.breakpoints.down(1300)]: {
      paddingTop: '4rem',
    },
  },

  passosTipografiaInvertido: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 auto',
    padding: '1rem',
    fontSize: '1.8rem',
    textAlign: 'right',
    lineHeight: '1.25rem',
    fontWeight: '700',
    color: COLORS.lightBlue,
    width: '70%',
    [theme.breakpoints.down(554)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down(1080)]: {
      marginTop: '0',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      padding: '0 1rem',
    },
    [theme.breakpoints.between(1060, 1500)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up(1700)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      top: '100px',
    },
  },

  inicioImagem: {
   /*  height: '100%', */
    width: '80%',
    marginTop: '1rem',
    marginLeft: '3rem',
    borderRadius: '1rem',
    [theme.breakpoints.down(1080)]: {
      width: '80%',
      height: 350,
      margin: '2rem auto',
    },
  },

  decretoLink: {
    color: COLORS.black,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.liveBlue,
    },
  },

  secaoVantagens: {
    height: '40vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.lighterBlue,
    /* padding: '5rem 0', */
    [theme.breakpoints.down(1080)]: {
      height: 'unset',
      padding: '3rem',
    },
  },

  vantagens: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  vantagensImgs: {
    width: '5rem',
    heigth: '5rem',
    margin: '0 2rem',
    filter: 'drop-shadow(2px 2px 8px #C7DBE1)',
    [theme.breakpoints.down(1080)]: {
      width: '6rem',
      heigth: '6rem',
    },
  },

  vantagensTitles: {
    fontWeight: '800',
    margin: '1rem 0 2rem 0',
    color: COLORS.darkerBlue,
    fontSize: '1.2rem',
  },

  vantagensSubtitles: {
    width: '70%',
    textAlign: 'center',
    fontWeight: '600',
    color: COLORS.mediumBlue,
    [theme.breakpoints.down(1080)]: {
      width: '100%',
      marginBottom: '4rem',
      fontSize: '1.1rem',
    },
  },

  vantagensValidade: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '30%',
    [theme.breakpoints.down(580)]: {
      maxWidth: '50%',
    },
  },

  vantagensPraticidade: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '30%',
    [theme.breakpoints.down(580)]: {
      maxWidth: '50%',
    },
  },

  vantagensSeguranca: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '22%',
    [theme.breakpoints.down(680)]: {
      maxWidth: '50%',
    },
    [theme.breakpoints.up(1080)]: {
      maxWidth: '30%',
    },
  },

  secaoComoUsar: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  comoUsarTitle: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    lineHeight: '1.5rem',
    color: COLORS.black,
    letterSpacing: '0.1rem',
    margin: '5rem auto 2rem auto',
    width: '70%',
    textAlign: 'left',
    padding: '1rem',
    [theme.breakpoints.down(554)]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    [theme.breakpoints.down(1080)]: {
      padding: '1rem',
    },
    [theme.breakpoints.between(1060, 1500)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up(1700)]: {
      fontSize: '2rem',
    },
  },

  secaoComoUsarNormal: {
    display: 'flex',
    height: '30rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
    padding: '1rem',
    margin: '1rem auto',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      justifyContent: 'column-reverse',
      height: 'unset',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      height: 'unset',
      margin: '1rem auto 3rem auto',
    },
  },

  secaoComoUsarInvertido: {
    width: '70%',
    margin: '1rem auto',
    padding: '1rem',
    display: 'flex',
    height: '30rem',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.white,
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      justifyContent: 'column-reverse',
      height: 'unset',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      margin: '0',
      padding: '0',
      height: '34rem',
    },
  },

  imageTutorial1: {
    width: '26rem',
    height: '10rem',
    margin: '4rem 0 4rem 4rem',
    borderRadius: '.5rem',

    [theme.breakpoints.down(360)]: {
      width: '16rem!important',
      height: 'unset',
      margin: '2rem!important',
    },
    [theme.breakpoints.between(360, 480)]: {
      width: '18rem!important',
      height: 'unset',
      margin: '2rem!important',
    },
    [theme.breakpoints.between(480, 580)]: {
      width: '20rem',
      height: 'unset',
    },
    [theme.breakpoints.between(580, 763)]: {
      width: '22rem',
    },
    [theme.breakpoints.between(763, 1080)]: {
      width: '24rem',
    },
    [theme.breakpoints.down(1080)]: {
      /* margin: '2rem 0 2rem auto', */
      margin: '2rem auto',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      margin: '2rem 0 2rem auto',
      width: '23rem!important',
    },
  },

  imageTutorial: {
    width: '25rem',
    height: '20rem',
    marginTop: '1rem',
    borderRadius: '.5rem',
    cursor: 'pointer',
  },

  imageTutorial2: {
    width: '25rem',
    height: '3.5rem',
    borderRadius: '.5rem',
    cursor: 'pointer',
    [theme.breakpoints.down(763)]: {
      width: 'unset',
      marginLeft: '0',
      height: '2.8rem',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      marginLeft: '0',
    },
  },

  imageForm: {
    marginLeft: '4rem',
    marginTop: '2rem',
    width: '17rem',
    height: '20rem',
    borderRadius: '.8rem',
    [theme.breakpoints.down(763)]: {
      width: 'unset',
      margin: '2rem auto!important',
    },
    [theme.breakpoints.down(1080)]: {
      margin: '2rem 0 2rem 4rem',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      marginLeft: '4rem',
    },
  },

  passo3Img: {
    width: '18rem',
    height: '5rem',
    borderRadius: '.5rem',
    [theme.breakpoints.down(480)]: {
      margin: '2rem!important',
    },
    [theme.breakpoints.down(1080)]: {
      margin: '2rem auto 2rem auto',
    },
  },

  textTutorial: {
    fontSize: '1.2rem',
    lineHeight: '2rem',
    fontWeight: '500',
    color: COLORS.black,
    width: '40%',
    [theme.breakpoints.down(500)]: {
      width: '100%!important',
    },
  },

  textTutorialLeft: {
    textAlign: 'left',
    marginTop: '2rem',
    [theme.breakpoints.down(1080)]: {
      margin: '3rem auto .5rem 0',
      width: '60%',
    },
    [theme.breakpoints.between(1080, 1280)]: {
      marginLeft: '0',
      width: '40%',
    },
  },

  textTutorialRight: {
    textAlign: 'right',
    [theme.breakpoints.down(1080)]: {
      margin: '3rem 0 2.5rem auto',
      width: '60%',
    },
  },

  textStyled: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    lineHeight: '1.25rem',
    color: COLORS.liveBlue,
  },

  botoes: {
    backgroundColor: COLORS.liveBlue,
    color: COLORS.white,
    fontSize: '.9rem',
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    letterSpacing: '0.02857em',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    textTransform: 'none',
    width: '16rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: COLORS.darkBlue,
    },
  },

  secaoDattaIdPropaganda: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.darkGreen,
    padding: '5rem 12rem 4rem 12rem',
  },

  dattaIdPropagandaTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '1.5rem',
    color: COLORS.white,
    letterSpacing: '0.2rem',
    textAlign: 'center',
    padding: '2rem 12rem',
  },

  textDattaId: {
    fontSize: '1.2rem',
    lineHeight: '1.9rem',
    fontWeight: '700',
    color: COLORS.white,
    width: '50%',
    textAlign: 'left',
  },

  containerTextoLogo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4rem 16rem',
  },

  dattaIdLogo: {
    width: '15rem',
    height: '15rem',
    borderRadius: '1rem',
  },

  badges: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid container justifyContent="center" align="center">
        <Grid container alignItems="center">
          <NavBar />
        </Grid>
        <Grid className={classes.body}>
          <Grid className={classes.inicioWrapper}>
            <Grid container className={classes.secaoInicio}>
              <div className={classes.divInicio}>
                <Box className={classes.titleAndImage}>
                  <Box className={classes.titleAndSubText}>
                    <Typography className={classes.title}>
                      Adeque seus documentos digitais de acordo com o&nbsp;
                      <a
                        href="https://www.in.gov.br/en/web/dou/-/decreto-n-10.278-de-18-de-marco-de-2020-248810105"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.decretoLink}
                      >
                        Decreto Federal nº 10.278
                      </a>
                    </Typography>
                    <Typography className={classes.subText}>
                      Garanta a validade jurídica dos seus documentos
                      digitalizados, conferindo a eles os mesmos efeitos legais
                      dos documentos originais
                    </Typography>
                    <Box className={classes.inicioSubtitulos}>
                      <Box className={classes.inicioSubtitulosTipografia}>
                        Seguro
                        <CheckIcon style={{ margin: '0 0 .1rem .5rem' }} />
                      </Box>
                      <Box className={classes.inicioSubtitulosTipografia}>
                        Prático
                        <CheckIcon style={{ margin: '0 0 .1rem .5rem' }} />
                      </Box>
                      <Box className={classes.inicioSubtitulosTipografia}>
                        Fácil
                        <CheckIcon style={{ margin: '0 0 .1rem .5rem' }} />
                      </Box>
                      <Box className={classes.inicioSubtitulosTipografia}>
                        Gratuito
                        <CheckIcon style={{ margin: '0 0 .1rem .5rem' }} />
                      </Box>
                    </Box>
                  </Box>
                  {/* <img
                    src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
                    className={classes.inicioImagem}
                    alt="Mãos no teclado de um laptop"
                  /> */}
                
                  <iframe title="Como usar o Adequador" className={classes.inicioImagem}
                  src="https://www.youtube.com/embed/Y27vRCe52pk" 
                   frameborder="0" allowfullscreen height="350"></iframe>

                </Box>
              </div>
              {/* <Typography variant="h4" className={classes.title}></Typography>        */}
              {/* Removido temporariamente */}
              {/* <UploadWidget selectedFile={selectedFile} setSelectedFile={setSelectedFile}
              files={files} setFiles={setFiles} /> */}
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1rem auto',
                }}
              >
                <Button
                  className={classes.botoes}
                  onClick={() => history.push('/upload')}
                >
                  Ir para a página Upload
                  <ArrowForwardIcon
                    style={{ marginLeft: '.5rem', cursor: 'pointer' }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Grid className={classes.secaoVantagens}>
            <Grid className={classes.vantagens}>
              <Grid className={classes.vantagensValidade}>
                <img
                  src={validade}
                  className={classes.vantagensImgs}
                  alt="ícone de selo"
                />
                <Typography className={classes.vantagensTitles}>
                  Validade
                </Typography>
                <Typography
                  className={classes.vantagensSubtitles}
                  variant="subtitle2"
                >
                  Garanta a validade jurídica do seu documento digital!
                </Typography>
              </Grid>
              <Grid className={classes.vantagensPraticidade}>
                <img
                  src={praticidade}
                  className={classes.vantagensImgs}
                  alt="ícone de lâmpada"
                />
                <Typography className={classes.vantagensTitles}>
                  Praticidade
                </Typography>
                <Typography
                  className={classes.vantagensSubtitles}
                  variant="subtitle2"
                >
                  Adeque seus documentos digitais com um simples clique!
                </Typography>
              </Grid>
              <Grid className={classes.vantagensSeguranca}>
                <img
                  src={seguranca}
                  className={classes.vantagensImgs}
                  alt="ícone de escudo"
                />
                <Typography className={classes.vantagensTitles}>
                  Segurança
                </Typography>
                <Typography
                  className={classes.vantagensSubtitles}
                  variant="subtitle2"
                >
                  Tenha a certeza de que seus documentos digitais estarão 100%
                  adequados!
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Section 3 */}
          <Grid className={classes.secaoComoUsar}>
            <Typography variant="h4" className={classes.comoUsarTitle}>
              Como usar? É simples!
            </Typography>
            <Typography className={classes.passosTipografia}>
              Passo 1
            </Typography>
            <Grid className={classes.secaoComoUsarNormal}>
              <Typography
                className={`${classes.textTutorial} ${classes.textTutorialLeft}`}
              >
                Na página <em>Upload</em>, clique no botão{' '}
                <strong style={{ color: COLORS.liveBlue }}>‘Upload’ </strong>
                ou na caixa ao redor para escolher os documentos. Você também
                pode simplesmente arrastá-los para a área circulada em azul.
              </Typography>
              <img
                className={classes.imageTutorial1}
                src="https://i.imgur.com/Xumuilx.png"
                alt="Print de caixa de upload para selecionar documentos"
              />
            </Grid>
            <div className={classes.passo2Div}>
              <Typography className={classes.passosTipografiaInvertido}>
                Passo 2
              </Typography>
              <Grid className={classes.secaoComoUsarInvertido}>
                <Typography
                  className={`${classes.textTutorial} ${classes.textTutorialRight}`}
                >
                  Em seguida, os documentos aparecerão em fila para que você{' '}
                  <strong style={{ color: COLORS.liveBlue }}>
                    preencha os campos{' '}
                  </strong>
                  solicitados. Faça isso para cada documento se tiver feito
                  upload de mais de um.
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                  }}
                >
                  <img
                    className={classes.imageTutorial2}
                    src="https://i.imgur.com/TE6sQQL.png"
                    alt="Print de fila de arquivos"
                  />
                  <img
                    className={classes.imageTutorial && classes.imageForm}
                    src="https://i.imgur.com/gAzjv81.png"
                    alt="Print de formulário para inserir dados"
                  />
                </div>
              </Grid>
            </div>
            <Typography className={classes.passosTipografia}>
              Passo 3
            </Typography>
            <Grid className={classes.secaoComoUsarNormal}>
              <Typography
                className={`${classes.textTutorial} ${classes.textTutorialLeft}`}
              >
                Por fim, adicione seu certificado digital ao documento apertando
                no botão{' '}
                <strong style={{ color: COLORS.liveBlue }}>
                  'Adequar documento'
                </strong>
                ! Pronto, seu documento estará no formato correto e carregando
                os metadados necessários!
                <br />
                <strong style={{ fontSize: '1.1rem' }}>
                  Não esqueça de salvar seu arquivo antes de fechar o site!
                </strong>
              </Typography>
              <img
                className={classes.imageTutorial && classes.passo3Img}
                src="https://i.imgur.com/OxdMVxU.png"
                alt="Print de botão 'salvar' após formulário"
              />
            </Grid>
          </Grid>
          <AdvertiseDialog />
          {/* Section 5 - Footer */}
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
