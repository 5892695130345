const colors = {
  white: '#FFFFFF',
  background: '#FBFBFF',

  lightGray0: '#F3F3F3',
  lightGray1: '#E7E7E7',
  lightGray2: '#EFEFEF',
  lightGray3: '#DEDEDE',
  lightGray4: '#D4D4D4',
  lightGray5: '#E4E5E9',

  mediumGray1: '#BABABA',
  mediumGray2: '#B8B8B8',
  mediumGray3: '#A7A7A7',
  mediumGray4: '#A7A3A3',
  mediumGray5: '#858585',

  gray1: '#535353',
  gray2: '#484848',
  gray3: '#3A3A3A',
  gray4: '#373737',
  gray5: '#333333',

  black: '#222222',
  pureBlack: '#000000',

  lightGreen: '#23AD0C',
  darkGreen: '#0D5870',

  lightRed: '#FF8A8A',
  redWarning: '#FF4646',

  blue: '#19B1D2',
  lighterBlue: '#D1E7ED',
  lightBlue: '#A4D2E0',
  liveBlue: '#18B1E1',
  mediumBlue: '#127596',
  darkBlue: '#1494BC',
  darkerBlue: '#0D5870',
};

export default colors;
